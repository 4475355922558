/*************************************
* File generated by Slyck IDE
* Global styles
*************************************/

html, body, #root {
  height: 100%;
}
.content-section.reverse .content-section-text {
  grid-column: 2;
}
.rainbow-text {
  margin-top: 18rem;
  margin-bottom: 12rem;
}
@media (max-width: 768px) {
  .rainbow-text {
    font-size: 7rem !important;
  }
}
.indent {
  padding-left: 4rem;
  border-left: 4px solid #f3f3f3;
}
.nav a {
  text-decoration: none;
  color: black;
  margin-right: 2.4rem;
  display: inline-flex;
  font-weight: 400;
}
.content-section.reverse img {
  grid-column: 1;
}
img {
  max-width: 100%;
  height: auto;
}
html {
  font-size: 62.5%;
}
h2 {
  font-size: 4.8rem;
  margin: 1.6rem 0;
  line-height: 1.1;
  font-weight: 600;
}
@media (max-width: 768px) {
  h2 {
    font-size: 3.8rem;
  }
}
h1 {
  font-size: 7rem;
  margin: 1rem 0;
  line-height: 1;
  font-weight: 600;
}
@media (max-width: 768px) {
  h1 {
    font-size: 6rem;
  }
}
body {
  margin: 0;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
  font-size: 1.6rem;
  font-weight: 300;
}
a {
  color: #4f95fa;
  text-decoration: none;
  display: inline-block;
}
:root {
  --max-width: 120rem;
  --sub-heading-size: 2.8rem;
  --primary-color: #167ffe;
}
* {
  box-sizing: border-box;
}
